import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
export default new VueRouter({
  routes: [{
    path: '/',
    name: 'index',
    component: () => import('@/pages/index.vue'),
    meta:{//初始化的meta
      isShow:true,
      title:'',
      content:{
        title:'',
        keywords:'',
        description:'',
      }
    },
    children: [{
        path: '/indexer',
        name: 'indexer',
        component: () => import('@/pages/indexer/indexer.vue'),
      }, {
        path: '/Finddetails',
        name: 'Finddetails',
        component: () => import('@/pages/indexer/Finddetails.vue'),
      }, {
        path: '/transferdetails',
        name: 'transferdetails',
        component: () => import('@/pages/indexer/transferdetails.vue'),
      }, {
        path: '/successList',
        name: 'successList',
        component: () => import('@/pages/indexer/successList.vue'),
      }, {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/indexer/about.vue'),
      }, {
        path: '/member',
        name: 'member',
        component: () => import('@/pages/indexer/member.vue'),
      }, {
        path: '/release',
        name: 'release',
        component: () => import('@/pages/indexer/release.vue'),
      },{
        path: '/edit',
        name: 'edit',
        component: () => import('@/pages/indexer/edit.vue'),
      }, {
        path: '/payment',
        name: 'payment',
        component: () => import('@/pages/indexer/payment.vue'),
      }, {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/indexer/login.vue'),
      }, {
        path: '/BusinessEntryApplication',
        name: 'BusinessEntryApplication',
        component: () => import('@/pages/indexer/BusinessEntryApplication.vue'),
      }, {
        path: '/CityPartners',
        name: 'CityPartners',
        component: () => import('@/pages/indexer/CityPartners.vue'),
      }, {
        path: '/Cityagent',
        name: 'Cityagent',
        component: () => import('@/pages/indexer/Cityagent.vue'),
      },
      {
        path: '/Nearby',
        name: 'Nearby',
        component: () => import('@/pages/indexer/Nearby.vue'),
      }, {
        path: '/SecondhandGoods',
        name: 'SecondhandGoods',
        component: () => import('@/pages/indexer/SecondhandGoods.vue'),
      },
      
    ]
  }, {
    path: '/guanyu',
    name: 'guanyu',
    component: () => import('@/pages/view/guanyu.vue'),
  }, {
    path: '/jjy',
    name: 'jjy',
    component: () => import('@/pages/jjy.vue'),
  }, {
    path: '/logincode',
    name: 'logincode',
    component: () => import('@/pages/logincode.vue'),
  }]
})
// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })
//解决路由重复点击问题
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// export default router