import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "amfe-flexible/index"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import style from "@/assets/css/style.css";
Vue.config.productionTip = false
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
router.afterEach(() => {
  window.scrollTo(0, 0);
});
new Vue({
  router,
  store,
  ElementUI,
  style,
  render: h => h(App)
}).$mount('#app')
Vue.use(ElementUI);
